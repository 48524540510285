import * as React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Layout from "../../components/layout";
import VideoJS from "../../components/videojs";

const VOD = ({ data }) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: data.mdx.frontmatter.m3u8_url,
        type: "application/x-mpegURL",
      },
    ],
    poster: data.mdx.frontmatter.thumbnail.childImageSharp.fixed.src,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <h1 className="text-3xl font-bold">{data.mdx.frontmatter.title}</h1>
      <div>
      {data.mdx.frontmatter.authors.map((authors) => (
        <span class="text-white mr-4">{authors}</span> 
      ))}
      </div>

      <div className="my-2">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>



      <MDXProvider>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>

      <Link to={`/${data.mdx.frontmatter.returnpage}`} className="btn btn-selected text-white mt-10 mb-20 bg-black/50 hover:bg-black">
        ← Back to video list
      </Link>

      <br/><br/>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        authors
        team
        department
        length
        m3u8_url
        returnpage
        thumbnail {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 400)
            fixed(width: 960) {
              src
            }
          }
        }
        date
      }
    }
  }
`;

export default VOD;
